import React from "react";
import {FaLongArrowAltLeft} from 'react-icons/fa'
import CardService from "../../components/CardService";
import services from "../../data/services";
import{
    ContainerCardsServices,
    ContainerServices,
    NavBarService,
}from './style'
import { useWindowSize } from "../../hooks/useWindowSize";
const Services = ()=>{
    let{width,height} = useWindowSize()
    return(
        <ContainerServices>    
            <NavBarService width={width} height={height}>
                <a href="/"><FaLongArrowAltLeft/></a>
                Serviços
            </NavBarService>
            <ContainerCardsServices>
                {
                    services.map((service)=>{
                        return <CardService icon={service.icon} title={service.title} description={service.description} />
                    })
                }
            </ContainerCardsServices>
        </ContainerServices>
    )
}

export default Services;