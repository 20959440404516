import styled from "styled-components";


export const ContainerServices = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
        background-color: black;
        padding-top: 1rem;
        
        @media(min-width: 781px){
            
        }
        
        
        `
export const NavBarService = styled.div`
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        align-items: center;
        justify-content: flex-start;
        color: #b18b01;
        width: 100%;
        height: 3rem;
        background-color: black;
        margin-left: 2rem;
        font-weight: 600;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        gap: 1rem;
        & a{
            color: #b18b01;
            line-height: 1rem;
            font-size: ${(props)=> `${(props.width/props.height) * 2.5}rem`};
        }
        @media(min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 0.5}rem`};
                & a{
                        color: #b18b01;
                        line-height: 1rem;
                        font-size: ${(props)=> `${(props.width/props.height) * 0.9}rem`};
                }
        }


`

export const ContainerCardsServices = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        background-color: black;
        padding-bottom: 3rem;
        padding-top: 2rem;
        @media(min-width: 781px){
                display: grid;
                justify-items: center;
                grid-template-columns: 50% 50%;
        }

`

