import styled from "styled-components";

export const ContainerTerms = styled.div`
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        background-color: white;
        width: 100%;
        height: 100vh;
        @media(min-width: 781px){
            padding-top: 3rem;
        }


`
export const NavBarTerms = styled.div`
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        justify-content: flex-start;
        width: 100%;
        height: 3rem;
        margin-left: 1rem;
        color: #b18b01;
        font-weight: 600;
        gap: 1rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        & a{
            font-size: ${(props)=> `${(props.width/props.height) * 2.5}rem`};
            color: #b18b01;
            line-height: 1rem;
        }
        @media(min-width: 781px){
            font-size: ${(props)=> `${(props.width/props.height) * 0.7}rem`};
            & a{
                font-size: ${(props)=> `${(props.width/props.height) * 0.9}rem`};
                color: #b18b01;
                line-height: 1rem;
            }
        }

`
export const ContainerContentTerms = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        color: black;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 3rem;
        padding-bottom: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        & h3{
            font-weight: 700;
        }
        & span{
            color: #b18b01;
        }
        @media(min-width: 781px){
            font-size: ${(props)=> `${(props.width/props.height) * 0.5}rem`};
            & h3{
                font-weight: 700;
            }
            & span{
                color: #b18b01;
            }
        }


`