import React from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
    InsightContainer,
    InsightContainerHead,
    InsightBar,
    InsightContainerCards,
    InsightCardContainer,
    InsightCardTitle,
    InsightCardSubtitle,
} from './style'
const InsightSection = ()=>{
    let {width,height} = useWindowSize()
    return(
        <InsightContainer>
            <InsightContainerHead width={width} height={height}>
                Experiência e competência de quem ja conhece o mercado
            </InsightContainerHead>
            <InsightBar/>
            <InsightContainerCards>
                <InsightCardContainer>
                    <InsightCardTitle width={width} height={height}>
                        100%
                    </InsightCardTitle>
                    <InsightCardSubtitle width={width} height={height}>
                        Nossos serviços são realizados com total responsabilidade e em conformidade com o plano estratégico de nossos clientes.
                    </InsightCardSubtitle>
                </InsightCardContainer>
                <InsightCardContainer>
                    <InsightCardTitle width={width} height={height}>
                        150K
                    </InsightCardTitle>
                    <InsightCardSubtitle width={width} height={height}>
                        Varias empresas estão aumentando sua receita exponencialmente com a terceirização do seu financeiro, e adicionando valor ao seu negócio.
                    </InsightCardSubtitle>
                </InsightCardContainer>
            </InsightContainerCards>

        </InsightContainer>
    )
}

export default InsightSection