import React from "react";
import {AiOutlineWhatsApp} from "react-icons/ai"
import {
    ContainerContactUs,
    ContainerContactUsIcon,
    ContainerContactUsTitle,
} from "./style";
import {linkWhats} from "../../data/links"

const ContactUs = ()=>{

    const contact = ()=>{
        let link = document.createElement('a')
        link.target = '__blank'
        link.href = linkWhats
        link.click()
    }
    return(
        <ContainerContactUs onClick={contact}>
            <ContainerContactUsIcon>
            <AiOutlineWhatsApp className="icon-whats-contact-us"/>
            </ContainerContactUsIcon>
            <ContainerContactUsTitle>
                fale conosco
            </ContainerContactUsTitle>
        </ContainerContactUs>
    )
}

export default ContactUs