import React from "react";

import{
    CardContainer,
    CardIcon,
    CardTitle,
    CardDescription,
} from './style'
import { useWindowSize } from "../../hooks/useWindowSize";
const CardService = ({icon,title,description})=>{
    let {width, height} = useWindowSize()
    return(
        <CardContainer>
            <CardIcon src={icon} width={width} height={height}>
            </CardIcon>
            <CardTitle width={width} height={height}>
                {title}
            </CardTitle>
            <CardDescription width={width} height={height}>
                {description}
            </CardDescription>
        </CardContainer>
    )
}

export default CardService