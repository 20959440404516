import styled from "styled-components";



export const ContainerServiceSection = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        width: 100%;
        height: auto;
        padding-bottom: 2rem;


        @media (min-width: 781px){

        }

`

export const ContainerServiceHeader = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 2rem;
        gap: 1rem;
        @media(min-width: 781px){

        }

`

export const ServiceTitle = styled.div`
        display: flex;
        color: #b18b01;
        font-weight: 600;
        margin-left: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media(min-width: 781px){
                padding-left: 3rem;
                font-size: ${(props)=> `${(props.width/props.height) * 0.9}rem`};

        }

`

export const ServiceSubTitle = styled.div`
        display: flex;
        color: white;
        font-weight: 600;
        margin-left: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2.3}rem`};
        @media(min-width: 781px){
                padding-left: 3rem;
                font-size: ${(props)=> `${(props.width/props.height) * 1.5}rem`};

        }

`
export const ServiceDescription = styled.div`
        display: flex;
        color: #6d6d6d;
        font-weight: 600;
        margin-left: 2rem;
        margin-right: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media(min-width: 781px){
                padding-left: 3rem;
                max-width: 70%;
                font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};

        }

`

export const ContainerServiceCards = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;



        @media(min-width: 781px){
                display: grid;
                grid-template-columns: 50% 50%;
                justify-content: center;
                flex-direction: row;
        }
`
export const ServiceLinkAll = styled.a`
        display: flex;
        color: #b18b01;
        width: 100%;
        font-weight: 700;
        font-size: 1.1rem;
        text-decoration: none;
        margin-left: 4rem;
        margin-top: 2rem;

        @media(min-width: 781px){
                padding-left: 5rem;
        }

`