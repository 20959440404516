import styled from "styled-components";


export const InsightContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        background-color: #b18b01;
        padding-top: 3rem;
        
        @media(min-width: 781px){
                align-items: center;
                justify-content: flex-start;
                flex-direction: row;
                height: auto;
                width: 100%;
        }

`

export const InsightContainerHead = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 3rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2.5}rem`};

        @media(min-width: 781px){
                padding-left: 3rem;
                margin-bottom: 1rem;
                font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        }

`

export const InsightBar = styled.div`
        display: flex;
        width: 50%;
        align-items: center;
        justify-content: center;
        height: 0.1rem;
        background-color: black;
        margin-bottom: 2rem;

        @media(min-width: 781px){
                align-items: flex-start;
                justify-content: flex-start;
                height: 0.1rem;
                transform: rotate(90deg);
                width: 100%;
        }



`

export const InsightContainerCards = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
        margin-right: 2rem;
        padding-bottom: 2rem;
        gap: 2rem;
        @media(min-width: 781px){

        }

`
export const InsightCardContainer = styled.div`
        display: flex;
        flex-direction: column;
        @media(min-width: 781px){

        }

`

export const InsightCardTitle = styled.div`
        display: flex;
        color: black;
        font-weight: 700;
        font-size: ${(props)=> `${(props.width/props.height) * 5}rem`};
        @media(min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 2.5}rem`};
        }

`
export const InsightCardSubtitle = styled.div`
        display: flex;
        color: #ccc;
        font-weight: 600;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media(min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 0.7}rem`};
        }

`