import React from "react";
import {FiAlignJustify,FiAlignLeft} from "react-icons/fi"
import {AiOutlineWhatsApp} from "react-icons/ai"
import logo from "../../img/logo.png"
import LinkDesktop from "../LinkDesktop"
import{
ContainerNavBar,
IconMenu,
Whats,
ContainerTitle,
ContainerLinks,
Logo,
Title
} from './style';



const NavBar = ({menuOpen, setMenuOpen,goAbout,goService,goContact})=>{
    const toggleMenu = ()=>{
        return setMenuOpen(!menuOpen)
    }
    return(
        <ContainerNavBar>
            <IconMenu isOpen={menuOpen} onClick={toggleMenu}>
                <FiAlignJustify className="icon-menu-closed"/>
                <FiAlignLeft className="icon-menu-open"/>
            </IconMenu>
            <Whats href="https://wa.me/5584997064482" target="_blank">
                <AiOutlineWhatsApp className="icon-whats"/>
            </Whats>
            <ContainerTitle>
                <Logo src={logo}/>
                <Title>gestão financeira</Title>
            </ContainerTitle>
            <ContainerLinks>
                <LinkDesktop id="d-about" title={"Sobre"} onClick={goAbout}/>
                <LinkDesktop id="d-service" title={"Serviços"} onClick={goService}/>
                <LinkDesktop id="d-contact" title={"Contato"} onClick={goContact}/>
            </ContainerLinks>
        </ContainerNavBar>
    )
    
}

export default NavBar;