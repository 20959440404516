import styled from "styled-components";


export const ContainerContactUs = styled.div`
    display: none;
    position: relative;
    z-index: 1;
    @media (min-width: 781px){
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
        cursor: pointer;
        transition: 0.3s;
        opacity: 0.85;
        &:hover{
            opacity: 1;
            transition: 0.3s;
        }
    }
`;

export const ContainerContactUsIcon = styled.div`
    @media (min-width: 781px){
        display: flex;
        .icon-whats-contact-us{
            color: #b18b01;
            font-size: 1.2rem;
        }
    }
`;

export const ContainerContactUsTitle = styled.div`
    @media (min-width: 781px){
        display: flex;
        color: #b18b01;
        font-size: 1.1rem;
        font-weight: 500;
    }
`;