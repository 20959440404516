import React from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import {FaLinkedinIn,FaInstagram,FaFacebookF} from 'react-icons/fa'
import {
    ContactSectionContainer,
    ContactContainerHeader,
    ContactSectionContainerHead,
    ContactHeadTitle,
    ContactHeadSubTitle,
    ContactContainerLinks,
    ContactContainerFooter,
    ContactFooterTitle,
    ContactContainerPrivacyAndTerms,
} from './style'
const ContactSection = ({id})=>{
    let {width,height} = useWindowSize()
    return(
        <ContactSectionContainer id={id}>
            <ContactContainerHeader>
                <ContactSectionContainerHead>
                    <ContactHeadTitle width={width} height={height}>
                        Contato
                    </ContactHeadTitle>
                    <ContactHeadSubTitle width={width} height={height}>
                        Nos conheça melhor
                    </ContactHeadSubTitle>
                </ContactSectionContainerHead>
                <ContactContainerLinks>
                    <a href="https://www.linkedin.com/in/alcynara-nogueira-dos-santos-fraga-823551240" target="_blank" rel="noreferrer"><FaLinkedinIn/></a>
                    <a href="https://www.instagram.com/nn.gestaofinanceira/" target="_blank" rel="noreferrer"><FaInstagram/></a>
                    <a href="https://www.facebook.com/nn.gestaofinanceira/" target="_blank" rel="noreferrer"><FaFacebookF/></a>
                </ContactContainerLinks>
            </ContactContainerHeader>
            <ContactContainerFooter>
                <ContactFooterTitle width={width} height={height}>
                &copy;2023 NN, Todos os direitos reservados
                </ContactFooterTitle>
                <ContactContainerPrivacyAndTerms width={width} height={height}>
                    <a href="/terms">termos</a>
                    <a href="/privacy">privacidade</a>
                </ContactContainerPrivacyAndTerms>
            </ContactContainerFooter>
        </ContactSectionContainer>
    )
}

export default ContactSection