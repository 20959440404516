import styled from "styled-components";

export const ContainerAbout = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 2rem;
        @media (min-width: 781px){
                cursor: auto;
        }
`
export const AboutTitle = styled.div`
        display: flex;
        color: #b18b01;
        align-items: flex-start;
        width: 100%;
        justify-content: flex-start;
        font-weight: 600;
        margin-top: 3rem;
        padding-left: 3rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media (min-width: 781px){
                cursor: auto;
                padding-left: 9rem;
                font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};
        }

`

export const AboutSubTitle = styled.span`
        display: flex;
        align-items: center;
        height: auto;
        justify-content: flex-start;
        font-weight: 700;
        color: black;
        margin-top: 1rem;
        margin-left: 1.5rem;
        margin-right: 2rem;
        line-break: auto;
        font-size: ${(props)=> `${(props.width/props.height) * 2.2}rem`};

        @media (min-width: 781px){
                cursor: auto;
                padding-left: 3rem;
                font-size: ${(props)=> `${(props.width/props.height) * 1.2}rem`};
        }

`
export const ContainerHead = styled.div`
   
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        cursor: pointer;
        
    @media (min-width: 781px){
        cursor: auto;
    }

`;

export const ContainerImageTopics = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        cursor: pointer;
        
    @media (min-width: 781px){
        cursor: auto;
        flex-direction: row;
        align-items: flex-start;
    }

`;

export const AboutImage = styled.img`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        height: auto;
        margin-top: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;


        @media(min-width: 781px){
                width: 90%;
                height: auto;
                cursor: auto;
        }


`
export const AboutTopics = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        gap: 2rem;

        @media (min-width: 781px){
                display: grid;
                justify-content: flex-start;
                align-items: flex-start;
                grid-template-columns: 50% 50%;
        }       

`

export const ContainerTopic = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 2rem;
        margin-right: 2rem;
        @media (min-width: 781px){
                align-items: flex-start;
                justify-content: flex-start;
        } 

`
export const TopicLine = styled.div`
        display: flex;
        width: 5rem;
        height: 0.06rem;
        background-color: #a7a7a7;
        margin-bottom: 0.2rem;
`
export const TopicTitle = styled.div`
        display: flex;
        font-weight: 700;
        color: black;
        margin-bottom: 1rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2.2}rem`};
        @media (min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};
        }  

`
export const TopicDescription = styled.div`
        display: flex;
        font-weight: 500;
        width: 90%;
        color: #a7a7a7;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media (min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 0.6}rem`};
        } 

`