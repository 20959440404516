import React from "react";
import {useWindowSize} from '../../hooks/useWindowSize'
import {FaLongArrowAltLeft} from 'react-icons/fa'
import{
    ContainerPrivacy,
    NavBarPrivacy,
    ContainerContentPrivacy,
}from './style'
import privacy from "../../data/privacy";
import parse from 'html-react-parser'
const Privacy = ()=>{
    let {width,height} = useWindowSize()
    return(
        <ContainerPrivacy>
            <NavBarPrivacy  width={width} height={height}>
                <a href="/"><FaLongArrowAltLeft/></a>
                Política de Privacidade
            </NavBarPrivacy>
            <ContainerContentPrivacy width={width} height={height}>
                {parse(privacy)}
            </ContainerContentPrivacy>
        </ContainerPrivacy>
    )
}

export default Privacy;