import cashFlow from '../img/fluxo_de_caixa.png'
import implantation from '../img/treinamento.png'
import bankScheduling from '../img/agendamento_bancario.png'
import accountControl from '../img/controle_de_contas.png'
import bankReconciliation from '../img/conciliacao_bancaria.png'
import cardReconciliation from '../img/conciliacao_cartoes.png'
import notesEmission from '../img/emissao_notas.png'
import reportResults from '../img/relatorios.png'
let services = [
    {
        icon: cashFlow,
        title: 'Fluxo de Caixa',
        description: 'Realização de fluxo de caixa, e afins'
    },
    {
        icon: implantation,
        title: 'Treinamento/Implantação',
        description: 'Verificação de melhores sistemas e treinamento pra implantar os mesmos'
    },
    {
        icon: bankScheduling,
        title: 'Agendamento Bancario',
        description: 'Agendamento/Pagamento de contas bancarias'
    },
    {
        icon: accountControl,
        title: 'Controle de Contas',
        description: 'Controle de contas a pagar e receber'
    },
    {
        icon: bankReconciliation,
        title: 'Conciliação Bancaria',
        description: 'Conciliação entre contas e extratos bancarios'
    },
    {
        icon: cardReconciliation,
        title: 'Conciliação de Cartões',
        description: 'Conciliação de contas de cartões junto a administradoras'
    },
    {
        icon: notesEmission,
        title: 'Emissão de Notas',
        description: 'Emissão de notas/boletos e envio dos mesmos'
    },
    {
        icon: reportResults,
        title: 'Relatorio de Resultados',
        description: 'Geração de relatórios do negócio'
    }
]

export default services