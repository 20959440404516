import styled from "styled-components";

export const ContainerNavBar = styled.div`
    display: flex;
    position: fixed;
    background-color: #333;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    z-index: 100;
`;
export const IconMenu = styled.div`
    display: none;
    @media (max-width: 780px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 2rem;
        cursor: pointer;
        .icon-menu-open{
            display: ${(props) => props.isOpen ? "flex":"none"};
            color: #b18b01;
            width: 22px;
            height: 22px;
        }
        .icon-menu-closed{
            display: ${(props) => props.isOpen ? "none":"flex"};
            color: #b18b01;
            width: 22px;
            height: 22px;
        }
        
    }
`;

export const Whats = styled.a`
    display: none;
    @media (max-width: 780px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    cursor: pointer;
    .icon-whats{
        color: #b18b01;
        width: 20px;
        height: 20px;
    }
    
}

`;
//Desktop
export const ContainerTitle = styled.div`
    display: none;
    @media (min-width: 781px){
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5rem;
        width: auto;
        height: auto;
        
    }

`;
export const ContainerLinks = styled.div`
    display: none;
    height: 100vh;
    @media (min-width: 781px){
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-right: 5rem;
        gap: 1rem;
        
    }

`;
export const Logo = styled.img`
    display: none;
    @media (min-width: 781px){
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 28px;
        
    }

`;

export const Title = styled.div`
    display: none;
    @media (min-width: 781px){
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        
    }

`;
