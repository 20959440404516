import React from "react";
import{ContainerLink} from "./style";

const LinkMobile = ({href,title,onClick})=>{
    return(
        <ContainerLink href={href} onClick={onClick}>
            {title}
        </ContainerLink>
    )
}

export default LinkMobile;