import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from "./pages/Home";
import Services from './pages/Services';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import{createBrowserRouter,RouterProvider} from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>
  },
  {
    path: "/services",
    element: <Services/>
  },
  {
    path: "/terms",
    element: <Terms/>
  },
  {
    path: "privacy",
    element: <Privacy/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);