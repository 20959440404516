import React from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import {
    ContainerServiceSection,
    ContainerServiceHeader,
    ServiceTitle,
    ServiceSubTitle,
    ServiceDescription,
    ContainerServiceCards,
    ServiceLinkAll,
} from './style'
import CardService from '../CardService'
import cashFlow from '../../img/fluxo_de_caixa.png'
import accountControl from '../../img/controle_de_contas.png'
const ServiceSection = ({id})=>{
    let {width,height} = useWindowSize()
    return(
        <ContainerServiceSection id={id}>
            <ContainerServiceHeader>
                <ServiceTitle width={width} height={height}>
                    Serviços
                </ServiceTitle>
                <ServiceSubTitle width={width} height={height}>
                    Escolha o que precisa
                </ServiceSubTitle>
                <ServiceDescription width={width} height={height}>
                    Tenha o controle de escolher os serviços que deseja e de forma flexivel.
                </ServiceDescription>
            </ContainerServiceHeader>
            <ContainerServiceCards>
                <CardService icon={cashFlow} title='Fluxo de Caixa' description='Realização de fluxo de caixa, e afins'/>
                <CardService icon={accountControl} title='Controle de contas' description='Controle de contas a pagar e receber'/>
            </ContainerServiceCards>
            <ServiceLinkAll href="/services">Todos os serviços</ServiceLinkAll>
        </ContainerServiceSection>
    )
}

export default ServiceSection