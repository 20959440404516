import React from "react";
import {FaLongArrowAltLeft} from 'react-icons/fa'
import { useWindowSize } from "../../hooks/useWindowSize";
import {
    ContainerTerms,
    NavBarTerms,
    ContainerContentTerms,
} from './style'
import terms from "../../data/terms";
import parse from 'html-react-parser'
const Terms = ()=>{
    let {width,height} = useWindowSize()
    return (
        <ContainerTerms>
            <NavBarTerms width={width} height={height}>
                <a href="/"><FaLongArrowAltLeft/></a>
                Termos de Uso
            </NavBarTerms>
            <ContainerContentTerms width={width} height={height}>
                {parse(terms)}
            </ContainerContentTerms>
        </ContainerTerms>
    )
}

export default Terms;