import styled from "styled-components";



export const ContactSectionContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        background-color: white;
        padding-top: 3rem;
        padding-bottom: 2rem;
        @media(min-width: 781px){
                align-items: flex-start;
                justify-content: space-between;
        }

`
export const ContactContainerHeader = styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @media(min-width: 781px){
                align-items: flex-end;
                justify-content: space-between;
        }

`
export const ContactSectionContainerHead = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 2rem;
        width: 100%;
        @media(min-width: 781px){

        }

`
export const ContactHeadTitle = styled.div`
        display: flex;
        color: #b18b01;
        font-weight: 600;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        @media(min-width: 781px){
                padding-left: 3rem;
                font-size: ${(props)=> `${(props.width/props.height) * 0.9}rem`};
        }
`
export const ContactHeadSubTitle = styled.div`
        display: flex;
        color: black;
        font-weight: 700;
        font-size: ${(props)=> `${(props.width/props.height) * 4}rem`};
        @media(min-width: 781px){
                margin-top: 1rem;
                padding-left: 3rem;
                max-width: 70%;
                font-size: ${(props)=> `${(props.width/props.height) * 1.7}rem`};
        }
`

export const ContactContainerLinks = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 2rem;
        gap: 1rem;
        cursor: pointer;
        & a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            border: 1px solid black;
            border-radius: 50%;
        }
        @media(min-width: 781px){
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 1rem;
        }


`
export const ContactContainerFooter = styled.div`
        display: flex;
        color: #777;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        @media(min-width: 781px){

        }

`
export const ContactFooterTitle = styled.div`
        display: flex;
        margin-left: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};
        @media(min-width: 781px){
                padding-left: 3rem;
                font-size: ${(props)=> `${(props.width/props.height) * 0.5}rem`};
        }

`
export const ContactContainerPrivacyAndTerms = styled.div`
        display: flex;
        gap: 0.6rem;
        margin-right: 2rem;
        font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};
        & a{
            text-decoration: none;
            color: #777;
        }
        @media(min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 0.4}rem`};
        }
`