import React,{} from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import{
    ContainerDescribe,
    ContainerDescribeTitle,
    ContainerDescribeSubtitle,
    ContainerDescribeSubtitleText,
} from "./style"
import ContactUs from "../ContactUs";

const HomeDescribe = ()=>{
    let {width,height} = useWindowSize()
    return(
        <ContainerDescribe>
            <ContainerDescribeTitle innerWidth={width} innerHeight={height} >
                Simplifique<br/> a gestão de<br/> seu negócio
            </ContainerDescribeTitle>
            <ContainerDescribeSubtitle>
                <ContainerDescribeSubtitleText innerWidth={width} innerHeight={height}>
                    A nossa empresa te ajuda a<br/> gerenciar seu financeiro,
                    te<br/> proporcionando mais tempo<br/> pra se dedicar ao que precisa.
                </ContainerDescribeSubtitleText>
                <ContactUs/>
            </ContainerDescribeSubtitle>
        </ContainerDescribe>
    )
}

export default HomeDescribe