import styled from "styled-components";


export const ContainerPrivacy = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100vh;
        background-color: white;

        @media(min-width: 781px){
            padding-top: 3rem;
        }

`

export const NavBarPrivacy = styled.div`
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 3rem;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: white;
        color: #b18b01;
        font-weight: 600;
        gap: 1rem;
        padding-left: 1rem;
        font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
        & a{
            color: #b18b01;
            line-height: 1rem;
            font-size: ${(props)=> `${(props.width/props.height) * 2.5}rem`};
        }
        @media(min-width: 781px){
            font-size: ${(props)=> `${(props.width/props.height) * 0.7}rem`};
            & a{
            color: #b18b01;
            line-height: 1rem;
            font-size: ${(props)=> `${(props.width/props.height) * 0.9}rem`};
        }
        }

`
export const ContainerContentPrivacy = styled.div`
        display: flex;
        flex-direction: column;
        width: 90%;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 4rem;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 3rem;
        overflow-wrap: break-word;
        font-size: ${(props)=> `${(props.width/props.height) * 1.8}rem`};
        & a{
            text-decoration: none;
            color: #b18b01;
        }
        & h2{
            font-size: ${(props)=> `${(props.width/props.height) * 2.2}rem`};
            font-weight: 700;
        }
        & h3{
            font-size: ${(props)=> `${(props.width/props.height) * 2}rem`};
            font-weight: 700;
        }
        @media(min-width: 781px){
            margin-top: 2rem;
            font-size: ${(props)=> `${(props.width/props.height) * 0.5}rem`};
        & a{
            text-decoration: none;
            color: #b18b01;
        }
        & h2{
            font-size: ${(props)=> `${(props.width/props.height) * 0.8}rem`};
            font-weight: 700;
        }
        & h3{
            font-size: ${(props)=> `${(props.width/props.height) * 0.6}rem`};
            font-weight: 700;
        }
        }

`