import styled from "styled-components";


export const ContainerDescribe = styled.div`
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 10px;
        width: 100%;
        margin-top: 3rem;
        height: auto;
        cursor: pointer;
        
   
    @media (min-width: 781px){
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        height: auto;
        justify-content: space-between;
        padding: 2rem;
        gap: 1rem;
        cursor: auto;
        
    }

`;

export const ContainerDescribeTitle = styled.div`
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 700;
        width: 100%;
        height: auto;
        margin-left: 1.5rem;
        font-size: ${(prop)=> `${(prop.innerWidth/prop.innerHeight) * 4.5}rem`};
    @media (min-width: 781px){
        width: 50%;
        height: 100%;
        justify-content: flex-end;
        padding-right: 2.5rem;
        align-items: flex-start;
        font-size: ${(prop)=> `${(prop.innerWidth/prop.innerHeight) * 2}rem`};
    }
`; 

export const ContainerDescribeSubtitle = styled.div`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 1rem;
        margin-left: 1.5rem;
        
    @media (min-width: 781px){
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 3rem;
        gap: 1rem;
        width: 50%;
        height: 100%;
        font-weight: 500;
        color: #9e9e9e;
        margin-left: 5rem;
    }
`; 

export const ContainerDescribeSubtitleText = styled.div`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-weight: 500;
        color: #9e9e9e;
        margin-bottom: 3rem;
        font-size: ${(prop)=> `${(prop.innerWidth/prop.innerHeight) * 2.5}rem`};
        
    @media (min-width: 781px){
        margin-bottom: 0;
        align-items: flex-start;
        justify-content: center;
        font-size: ${(prop)=> `${(prop.innerWidth/prop.innerHeight) * 0.7}rem`};

    }
`; 