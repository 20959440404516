import styled from "styled-components";


export const ContainerHomeFooter = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        width: 100%;
        height: auto;
        cursor: pointer;
        
    @media (min-width: 781px){
        background-color: black;
        flex-direction: row-reverse;
        justify-content: space-between;
        cursor: auto;
    }

`;

export const ContainerImage = styled.img`
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.5rem;
        width: ${(props)=> `${(props.innerWidth/props.innerHeight) * 20}rem`};
        height: ${(props)=> `${(props.innerWidth/props.innerHeight) * 28}rem`};
        
    @media (min-width: 781px){
        width: ${(props)=> `${(props.innerWidth/props.innerHeight) * 9.5}rem`};
        height: ${(props)=> `${(props.innerWidth/props.innerHeight) * 13.5}rem`};
        margin-right: 3rem;
    }
    
`

export const ContainerImageSubtitle = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        padding-left: 2rem;
        padding-right: 2rem;
        text-align: left;
        padding-bottom: 2rem;
        font-size: ${(props)=> `${(props.innerWidth/props.innerHeight) * 4}rem`}; 
        
    @media (min-width: 781px){
        max-width: 30%;
        margin-left: 3rem;
        font-size: ${(props)=> `${(props.innerWidth/props.innerHeight) * 1.5}rem`}; 
        
    }
    
`