export const topics = [
    {
        title: "Estratégia",
        description: "Mais tempo pra planejar o seu negócio e menos tempo pra alcançar cada meta."
    },
    {
        title: "Finanças",
        description: "Acredite que suas finanças está com quem já tem conhecimento e responsabilidade."
    },
    {
        title: "Gerenciamento",
        description: "Gerencie seu tempo focando no que é importante e sem perder receita."
    },
    {
        title: "Otimização",
        description: "Otimize seus processos e aumente  a sua receita e se torne competitivo no seu nicho."
    },
]