import React from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import photo from "../../img/photo.png"
import{
    ContainerHomeFooter,
    ContainerImage,
    ContainerImageSubtitle,
}from './style'

const HomeFooter = ()=>{
    let{width,height} = useWindowSize()
    return(
        <ContainerHomeFooter>
            
            <ContainerImage innerWidth={width} innerHeight={height} src={photo}/>
            
            <ContainerImageSubtitle innerWidth={width} innerHeight={height}>
                BPO Financeiro é com a gente
            </ContainerImageSubtitle>
        </ContainerHomeFooter>
    )
}

export default HomeFooter