import React from "react";
import{ContainerLink} from "./style"
const LinkDesktop = ({title,onClick})=>{
    return(
        <ContainerLink onClick={onClick}>
            {title}
        </ContainerLink>
    )
}

export default LinkDesktop