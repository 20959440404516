import styled from "styled-components";

export const CardContainer = styled.div`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: auto;
        background-color: #111;
        margin-top: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
        border-radius: 0.5rem;
        padding: 3rem;
        gap: 1.5rem;
        transition: 0.3s;
        @media(min-width: 781px){
                transition: 0.3s;
                &:hover{
                        background-color: #b18b01;
                }
        }


`
export const CardIcon = styled.img`
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${(props)=> `${(props.width/props.height) * 4}rem`};
        height: ${(props)=> `${(props.width/props.height) * 4}rem`};
        @media(min-width: 781px){
                width: ${(props)=> `${(props.width/props.height) * 1.8}rem`};
                height: ${(props)=> `${(props.width/props.height) * 1.8}rem`};
        }



`
export const CardTitle = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        font-size: ${(props)=> `${(props.width/props.height) * 1.5}rem`};
        @media(min-width: 781px){
                font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};

        }


`
export const CardDescription = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6d6d6d;
        color: #aaa;
        font-weight: 600;
        font-size: ${(props)=> `${(props.width/props.height) * 1}rem`};
        @media(min-width: 781px){

        }


`