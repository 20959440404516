import React from 'react'
import { useWindowSize } from '../../hooks/useWindowSize'
import about1 from '../../img/about1.png'
import { topics } from '../../data/topics'
import{
    ContainerAbout,
    ContainerHead,
    ContainerImageTopics,
    AboutTitle,
    AboutSubTitle,
    AboutImage,
    AboutTopics,
    ContainerTopic,
    TopicLine,
    TopicTitle,
    TopicDescription,

}from './style'

const AboutSection = ({id})=>{
    let{width,height} = useWindowSize()
    return(
        <ContainerAbout id={id}>
            <ContainerHead>
                <AboutTitle width={width} height={height}>
                    Sobre
                </AboutTitle>
                <AboutSubTitle width={width} height={height}>
                    Quanto tempo voçê gasta por mês com as burocracias no seu negócio?
                </AboutSubTitle>
            </ContainerHead>
            <ContainerImageTopics>
                <AboutImage src={about1}/>
                <AboutTopics>
                    {topics.map(topic =>{
                        return <ContainerTopic>
                            <TopicLine/>
                            <TopicTitle width={width} height={height}>
                                {topic.title}
                            </TopicTitle>
                            <TopicDescription width={width} height={height}>
                                {topic.description}
                            </TopicDescription>
                        </ContainerTopic>
                    })
                        
                    }
                </AboutTopics>
            </ContainerImageTopics>
        </ContainerAbout>
    )
}

export default AboutSection