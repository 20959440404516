import styled from "styled-components";


export const ContainerLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 13px;
    transition: 0.3s;
    :hover{
        color: #b18b01;
        cursor: pointer;
        transition: 0.3s;

    }
`;