import styled from "styled-components";


export const ContainerLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    color: #b18b01;
    font-size: 25px;
    font-weight: 600;
    margin-top: 1rem;
    margin-left: 3rem;
    text-decoration: none;
    cursor: pointer;


`;