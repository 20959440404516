import React,{useState} from "react";
import{ContainerHome,MenuMobile} from './style'
import {
    NavBar,
    LinkMobile,
    HomeDescribe,
    HomeFooter,
    AboutSection,
    ServiceSection,
    InsightSection,
    ContactSection,
} from "../../components";

const Home = ()=>{
    const [menuOpen, setMenuOpen] = useState(false)
    const goAbout = ()=>{
        document.body.scrollTo({
        top: document.getElementById("s-about").offsetTop,
        behavior: "smooth",
        })
}
    const goAboutMobile = ()=>{
        setMenuOpen(false)
        goAbout()
    }
    const goServiceMobile = ()=>{
        setMenuOpen(false)
        goService()
    }
    const goContactMobile = ()=>{
        setMenuOpen(false)
        goContact()
    }
    const goService = ()=>{
        document.body.scrollTo({
        top: (document.getElementById("s-service").offsetTop) - 30,
        behavior: "smooth",
    })}
    const goContact = ()=>{
        document.body.scrollTo({
        top: document.getElementById("s-contact").offsetTop,
        behavior: "smooth",
    })}

    
    return(
        <ContainerHome>
            <NavBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} goAbout={goAbout} goService={goService} goContact={goContact}/>
            <MenuMobile isOpen={menuOpen}>
                <LinkMobile id='m-about' title={"Sobre"} onClick={goAboutMobile}/>
                <LinkMobile id='m-service' title={"Serviços"} onClick={goServiceMobile}/>
                <LinkMobile id='m-contact' title={"Contato"} onClick={goContactMobile}/>
            </MenuMobile>
            <HomeDescribe/>
            <HomeFooter/>
            <AboutSection id='s-about'/>
            <ServiceSection id='s-service'/>
            <InsightSection/>
            <ContactSection id='s-contact'/>
        </ContainerHome>
    )
}

export default Home