import styled from "styled-components";


export const ContainerHome = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background-color: #fff;
width: 100vw;
height: 100vh;
`;

export const MenuMobile = styled.div`
        display: ${(props) => props.isOpen ? "flex":"none"};
        position: fixed;
        transform: translateY(50px);
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 20px;
        width: 100%;
        height: 100vh;
        gap: 0.5rem;
        overflow-y: hidden;
        overflow-x: hidden;
        background-color: #fff;
        
`;